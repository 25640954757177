<template>
<div>
  <van-loading class="m20" size="60px" type="spinner" color="#1989fa">支付中...</van-loading>
  <div class="notice-text">如不能打开支付，请确认是否安装支付应用</div>
</div>
</template>

<script>
import {onBeforeUnmount, reactive} from "vue";
import {Loading} from "vant";
import {checkBalance} from "@/api/balance";
import {useStore} from "vuex";

export default {
  name: "WalletLoading",
  components:{
    [Loading.name]: Loading,
  },
  props: {
    payData: {
      type: Object,
      default: ()=>{
        return {
          pay_order_number:'',
          pay_url:'',
        }
      },
    }
  },
  setup(props,context) {
    const payData = reactive(props.payData);
    window.location.href = payData.pay_url
    const timer = setInterval(() => {
      // 这里调用调用需要执行的方法，1为自定义的参数，由于特殊的需求它将用来区分，定时器调用和手工调用，然后执行不同的业务逻辑
      checkOrderStatus()
    }, 2000) // 每两秒执行1次
    onBeforeUnmount(() => {
      clearInterval(timer)
    })
    const store = useStore()
    const checkOrderStatus = async (close = false) => {
      const checkRes = await checkBalance(payData.pay_order_number).then()
      const {pay_status} = checkRes.data;
      if (pay_status === 1 || close){
        clearInterval(timer)
        if(pay_status === 1){
          await store.commit('updateUserInfo')
        }
        context.emit('callBack')
      }
    }


    return {
      props,
      checkOrderStatus,
      context
    };
  },
}
</script>

<style scoped>
.notice-text{
  font-size: 12px;
  font-weight: 400;
  color: #A5ADB7;
  line-height: 20px;
}
</style>
