/**
 * length - 字符长度。注意：如果传第二个参数 “true”，则全角字符（汉子）计算为两个字符
 length(n)	请填写 n 个字符
 length(n~)	请至少填写 n 个字符
 length(~n)	请最多填写 n 个字符
 length(n1~n2)	请填写 n1 到 n2 个字符
 length(n~, true)	请至少填写 n 个字符（全角字符计算双字符）
 * range - 数值范围。注意：只验证范围，不验证是整数或者浮点数
 range(n~)	请填写不小于 n 的数
 range(~n)	请填写不大于 n 的数
 range(n1~n2)	请填写 n1 到 n2 的数
 range(n1~n2, false)	请填写 n1 到 n2 的数（不包含边界值）
 * integer - 整数：整数或者整数范围
 integer    整数
 integer(+)    正整数
 integer(+0)    正整数和零
 integer(-)    负整数
 integer(-0)    负整数和零
 * match - 两个字段匹配和比较
 match(name)    当前字段值必须和 name 字段的值匹配
 match(eq, name)    同上
 match(neq, name)    当前字段值必须和 name 字段值不同
 match(lt, name)    当前字段值必须小于 name 字段值
 match(gt, name)    当前字段值必须大于 name 字段值
 match(lte, name)    当前字段值必须小于等于 name 字段值
 match(gte, name)    当前字段值必须大于等于 name 字段值
 match(gte, name, date)    当前日期值必须大于等于 name 字段日期值
 match(gte, name, datetime)    当前时间值必须大于等于 name 字段时间值
 * 示例
 basicRules:{
	account:{
		label:'用户名',//自定义某些特定的字段名提示语
		rules:"required;mobile;integer(+);match(password)",//不用指定特殊的提示语，可直接配置规则
	},
	password:{
		label:'平台账号密码',
		rules:{
			"required":"请填写密码",//指定特殊的配置规则
		}
	},
	againPassword:{
		label: '确认密码',
		rules: {
			"required": "请再次输入密码",
			"sdkPassword": true,
			"length(1~5)":"自定义提示语",
		}
	}
}
 */
class formsValidator {
    isValidator(data, rules,isBreak = true) {
        const that = this;
        let ret = {
            'isValid': true, 'msg': isBreak ? '' : {}
        };
        if (typeof rules !== 'object') return false;
        for (let prop in rules) {
            const {isValid, msg} = that.isValid(data[prop], rules[prop],data,rules);
            if (!isValid) {
                ret.isValid = isValid;
                if(isBreak){
                    //一旦检测错误，直接返回
                    ret.msg = msg;
                    break
                }else{
                    ret.msg[prop] = msg;
                }
            }
        }
        return ret;
    }

    constructor() {
        this.config = {
            message: {
                0: "此处",
                fallback: "{0}格式不正确",
                loading: "正在验证...",
                error: "网络异常",
                timeout: "请求超时",
                required: "{0}不能为空",
                integer: {
                    '*': "请填写整数",
                    '+': "请填写正整数",
                    '+0': "请填写正整数或0",
                    '-': "请填写负整数",
                    '-0': "请填写负整数或0"
                },
                match: {
                    eq: "{0}与{1}不一致",
                    neq: "{0}与{1}不能相同",
                    lt: "{0}必须小于{1}",
                    gt: "{0}必须大于{1}",
                    lte: "{0}不能大于{1}",
                    gte: "{0}不能小于{1}"
                },
                range: {
                    rg: "请填写{1}到{2}的数",
                    gte: "请填写不小于{1}的数",
                    lte: "请填写最大{1}的数",
                    gtlt: "请填写{1}到{2}之间的数",
                    gt: "请填写大于{1}的数",
                    lt: "请填写小于{1}的数"
                },
                checked: {
                    eq: "请选择{1}项",
                    rg: "请选择{1}到{2}项",
                    gte: "请至少选择{1}项",
                    lte: "请最多选择{1}项"
                },
                length: {
                    eq: "请填写{1}个字符",
                    rg: "请填写{1}到{2}个字符",
                    gte: "请至少填写{1}个字符",
                    lte: "请最多填写{1}个字符",
                    eq_2: "",
                    rg_2: "",
                    gte_2: "",
                    lte_2: ""
                },
            }, // Custom rules
            rules: {
                digits: [/^\d+$/, "{0}请填写数字"],
                letters: [/^[a-z]+$/i, "{0}请填写字母"],
                date: [/^\d{4}-\d{2}-\d{2}$/, "{0}请填写有效的日期，格式:yyyy-mm-dd"],
                time: [/^([01]\d|2[0-3])(:[0-5]\d){1,2}$/, "{0}请填写有效的时间，00:00到23:59之间"],
                email: [/^[\w+-]+(\.[\w+-]+)*@[a-z\d-]+(\.[a-z\d-]+)*\.([a-z]{2,4})$/i, "{0}请填写有效的邮箱"],
                url: [/^(https?|s?ftp):\/\/\S+$/i, "{0}请填写有效的网址"],
                qq: [/^[1-9]\d{4,}$/, "{0}请填写有效的QQ号"],
                IDcard: [/^\d{6}(19|2\d)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)?$/, "请填写正确的身份证号码"],
                tel: [/^(?:(?:0\d{2,3}[- ]?[1-9]\d{6,7})|(?:[48]00[- ]?[1-9]\d{6}))$/, "请填写有效的电话号码"],
                mobile: [/^1[3-9]\d{9}$/, "手机号格式不正确"],
                zipcode: [/^\d{6}$/, "请检查邮政编码格式"],
                chinese: [/^[\u0391-\uFFE5]+$/, "请填写中文字符"],
                username: [/^\w{3,15}$/, "请填写3-15位数字、字母、下划线"],
                mixUsername: [/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/, "{0}为6-15位字母、数字组合"] //请填写6-15位数字、字母组合，不能包含空格、符号
                ,
                sdkUsername: [/^[A-Za-z]{1}(?=.*?[0-9_])[A-Za-z\d_]{5,14}$/, "{0}为字母开头的 6-15 位数字、字母组合"],
                password: [/^[\S]{6,16}$/, "请填写6-16位字符，不能包含空格"],
                mixPassword: [/^(?!\d+$)[\Sa-zA-Z0-9]{6,15}$/, "请填写6-15位字母、数字、字符组合，不能纯数字，不能包含空格"],
                sdkPassword: [/^([a-zA-Z])(?![a-zA-Z]+$)[0-9A-Za-z]{5,16}$/, "{0}格式不正确"] //请填写6-16位字母开头的数字、字母组合，不能包含空格、符号
                ,
                money: [/^(?:0|[1-9]\d*)(?:\.\d{1,2})?$/, "请填写有效的金额"],
                ip: [/^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})$/i, '请填写有效的 IP 地址'],
                numberStep2: [/^(([1-9]{1}\d*)|(0{1}))(\.\d{2})?$/i, '请保留小数点后两位'],
                numberStep1: [/^(([1-9]{1}\d*)|(0{1}))(\.\d{1})?$/i, '请保留小数点后一位'], //安全密码验证
                securityRule: [/^[\d+]{6}$/, "{0}格式不正确"], //必填校验
                required(value) {
                    let isValid = true;
                    if (!value) {
                        isValid = false;
                    }
                    return isValid;
                },
                //整数校验,整数或者整数范围
                integer(value, params) {
                    let re, z = '0|', p = '[1-9]\\d*', key = params ? params[0] : '*';
                    switch (key) {
                        case '+':
                            re = p;
                            break;
                        case '-':
                            re = '-' + p;
                            break;
                        case '+0':
                            re = z + p;
                            break;
                        case '-0':
                            re = z + '-' + p;
                            break;
                        default:
                            re = z + '-?' + p;
                    }
                    re = '^(?:' + re + ')$';
                    return new RegExp(re).test(value) || this.config.message['integer'][key];
                },
                //两个字段的匹配和比较,可以用于比较两个字段值是否相同，或是否不同，或大小关系，也可以用于比较日期或者时间
                /**
                 * date 日期值
                 * datetime 时间值
                 */
                match(value, params,data,rules) {
                    if (!params) return;
                    let paramsArr = params.split(',')
                    let isValid, type = paramsArr[1] ? paramsArr[0] : 'eq', a = value, b = paramsArr[1] ? data[(paramsArr[1].replace(' ', ''))] : data[(paramsArr[0].replace(' ', ''))];
                    let parser = paramsArr[2] ? paramsArr[2] : '';
                    let matchLabel = paramsArr[1] ? (rules[(paramsArr[1].replace(' ', ''))]?.label || '对比处') : (rules[(paramsArr[0].replace(' ', ''))]?.label || '对比处')
                    if (parser) {
                        if (/^date(time)?$/i.test(parser)) {
                            a = Date.parse(a.replace(/\.|-/g, '/'));
                            b = Date.parse(b.replace(/\.|-/g, '/'));
                        } else if (parser === 'time') {
                            a = +a.replace(/:/g, '');
                            b = +b.replace(/:/g, '');
                        }
                    }
                    if (type !== 'eq' && !isNaN(+a) && isNaN(+b)) {
                        return true;
                    }
                    switch (type) {
                        case 'lt':
                            isValid = +a < +b;
                            break;
                        case 'lte':
                            isValid = +a <= +b;
                            break;
                        case 'gte':
                            isValid = +a >= +b;
                            break;
                        case 'gt':
                            isValid = +a > +b;
                            break;
                        case 'neq':
                            isValid = a !== b;
                            break;
                        default:
                            isValid = a === b;
                    }
                    return isValid || this.renderMsg([this.config.message['match'][type],matchLabel]);
                },
                //字符长度,如果传第二个参数 “true”，则全角字符（汉子）计算为两个字符
                length(value, params) {
                    // eslint-disable-next-line no-control-regex
                    const doubleBytes = /[^\x00-\xff]/g;
                    const paramsArr = params.split(',')
                    let len = (paramsArr[1] === 'true' ? value.replace(doubleBytes, 'xx') : value).length;
                    return this.getRangeMsg.call(this,len,paramsArr, (paramsArr[1] ? '_2' : ''),'length');
                },
                //数值范围,只验证范围，不验证是整数或者浮点数
                range(value,params){
                    const paramsArr = params.split(',')
                    return this.getRangeMsg.call(this,value,paramsArr,'','range');
                }
            }
        }
        this.validator = (data, rules) => {
            return this.isValidator(data, rules)
        }
        this.isValid = (value, rule,data,rules) => {
            let ret = {
                isValid: true, msg: '通过'
            };
            if (typeof rule['rules'] == 'string') {
                let validRule = rule['rules'].split(';');
                for (let index in validRule) {
                    const {isCheckValid, checkMsg} = this.checkRule(validRule[index], value,'',data,rules);
                    if (!isCheckValid) {
                        ret.isValid = false;
                        let ruleMsg = checkMsg.replace(/\{0\|?([^}]*)\}/, (rule['label'] ? rule['label'] : this.config.message[0]));
                        ret.msg = ruleMsg;
                        break;
                    }
                }
            } else {
                for (let propKey in rule['rules']) {
                    let params = (typeof rule['rules'][propKey] == 'object') ? rule['rules'][propKey] : '';
                    const {isCheckValid, checkMsg} = this.checkRule(propKey, value, params,data,rules);
                    if (!isCheckValid) {
                        ret.isValid = false;
                        let ruleMsg = checkMsg.replace(/\{0\|?([^}]*)\}/, (rule['label'] ? rule['label'] : this.config.message[0]));
                        ret.msg = (typeof rule['rules'][propKey] == 'string') ? rule['rules'][propKey] : ruleMsg;
                        break;
                    }
                }
            }
            return ret;
        }
        this.checkRule = (ruleName, value, params,data,rules) => {
            let ret = {
                isCheckValid: true, checkMsg: '通过'
            }
            if (!params && ruleName.indexOf('(') != '-1') {
                let checkParams = ruleName.split('(');
                ruleName = checkParams[0];
                params = checkParams[1].replace(')', '');
            }
            if (typeof this.config.rules[ruleName] == 'function') {
                const returnRes = this.config.rules[ruleName].call(this, value, params,data,rules);
                if (!returnRes || typeof returnRes == 'string') {
                    ret.isCheckValid = false;
                    if (!params) {
                        ret.checkMsg = this.config.message[ruleName];
                    } else if (typeof params == 'string') {
                        ret.checkMsg = returnRes;
                    }
                }
            } else if (typeof this.config.rules[ruleName] == 'object') {
                if (!(this.config.rules[ruleName][0]).test(value)) {
                    ret.isCheckValid = false;
                    ret.checkMsg = this.config.rules[ruleName][1];
                }
            } else {
                console.log("不存在该方法");
            }
            return ret;
        }
        this.getRangeMsg = (len, params, suffix,type) => {
            if (!params) return;
            // eslint-disable-next-line no-unused-vars
            let result,msg=this.config.message[type], p = params[0].split('~'), e = params[1] === 'false', a = p[0], b = p[1], c = 'rg', args = [''],
                isNumber = +len === +len;
            function compare(large, small) {
                return !e ? large >= small : large > small;
            }
            if (p.length === 2) {
                if (a && b) {
                    if (isNumber && compare(len, +a) && compare(+b, len)) {
                        result = true;
                    }
                    args = args.concat(p);
                    c = e ? 'gtlt' : 'rg';
                } else if (a && !b) {
                    if (isNumber && compare(len, +a)) {
                        result = true;
                    }
                    args.push(a);
                    c = e ? 'gt' : 'gte';
                } else if (!a && b) {
                    if (isNumber && compare(+b, len)) {
                        result = true;
                    }
                    args.push(b);
                    c = e ? 'lt' : 'lte';
                }
            } else {
                if (len === +a) {
                    result = true;
                }
                args.push(a);
                c = 'eq';
            }
            if (suffix && msg[c + suffix]) {
                c += suffix;
            }
            args[0] = msg[c];
            return result || this.renderMsg(args);
        }
        this.renderMsg = (args) => {
            let tpl = args[0], i = args.length;
            if (!tpl) return;
            while (--i) {
                tpl = tpl.replace('{' + i + '}', args[i]);
            }
            return tpl;
        }
    }
}

const validator = new formsValidator
export default validator
