// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/6/6
// +-------------------------------------------------------------
// |Time: 18:12
// +-------------------------------------------------------------
import request from "@/utils/request";

//充值平台币
export function payBalance(data){
    return request({
        method: 'post',
        url: '/balance/pay-balance',
        data,
    })
}
//平台币支付状态查询
export function checkBalance(pay_order_number){
    return request({
        method: 'post',
        url: '/balance/check-balance',
        data:{pay_order_number},
    })
}
